import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ProblemsList from './ProblemsList';
import './Problems.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container" xs={12}>
                <Typography variant="h4" className="title-main" align="center">PROBLEMS WITH COUNTERFEIT</Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider" />
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="problems-content-container">
                    <Box className="problems-problem-list-container">
                        <Box className="problems-problem-list-bg" />
                        <Box className="problems-decorative-box f-box-a" />
                        <Box className="problems-decorative-box f-box-b" />
                        <Box className="problems-problem-list">
                            <ProblemsList />
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Box className="problems-decorative-title">PROBLEMS</Box>
        </Grid>
    );
};
