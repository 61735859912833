import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default () => {
    return (
        <Box className="home-data-typography-container">
            <Box className="home-title">
                <Typography variant="h3">REAL QR</Typography>
                <Typography variant="h6">Join the fight against counterfeit!</Typography>
            </Box>
            <Box className="home-description">
                <Typography variant="body1">
                    Counterfeit is breaking consumer trust, 
                    destroying brand image and decreasing revenues. 
                    Do not let it kill your market.
                </Typography>
            </Box>
            <Box className="home-data-display home-brand-honesty">
                <Box className="home-percentage-value">91</Box>
                <Box className="home-percentage-sign">%</Box>
                <Box className="home-data-description">of consumers purchase because of <Box component="span" className="home-data-highlight">brand honesty</Box></Box>
            </Box>
            <Box className="home-data-display home-authentic-brands">
                <Box className="home-percentage-value">63</Box>
                <Box className="home-percentage-sign">%</Box>
                <Box className="home-data-description">
                    buy from 
                    <Box component="span" className="home-data-highlight">
                    {' authentic brands'}
                    </Box> over competitors without transparency
                </Box>
            </Box>
            <Box className="home-data-display home-source">
                <Box className="home-percentage-value">43</Box>
                <Box className="home-percentage-sign">%</Box>
                <Box className="home-data-description">of buyers seek information from <Box component="span" className="home-data-highlight">the source</Box></Box>
            </Box>
            <Typography variant="subtitle2" style={{ fontStyle: 'italic', color: '484848' }}>
                Data source from (Bonfire, 2019) and (Nielson, 2019)
            </Typography> 
        </Box>
    );
};