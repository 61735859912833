import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import './ThankYou.css';

export default () => {
    let mail = 'mailto:?subject=Product%20Authentication%20Solution';
    mail += '&body=Hi%20dear%20friend%2C%0A%0AI%20found%20a%20unique%20';
    mail += 'solution%20to%20help%20us%20with%20product%20authentication';
    mail += '%20promoted%20by%20OPSOLUTIONS.%20They%20use%20a%20';
    mail += 'patented%20micro-print%20technology%20that%20prevents%20';
    mail += '3rd%C2%A0party%20from%20copying%20their%C2%A0Qr%C2%A0codes.%0A%0AYou%20can%20';
    mail += 'find%20out%20more%20about%20their%20solution';
    mail += '%20here%3A%0Ahttps%3A%2F%2Freal-qr.com';
    mail += '%0A%0AThank%20you%20and%20take%20care!%0A';
    return (
        <Grid container={true} className="thanks-container">
            <Box className="home-bg" />
            <Grid item={true} xs={12} className="thanks-content">
                <Box style={{ width: '100%' }}>
                    <Typography variant="h5" gutterBottom={true} align="center">
                        Thank you for enquiring with us!
                    </Typography>
                    <Typography variant="h5" align="center" style={{ marginBottom: '1rem' }}>
                        Our sales team will contact you soon.
                    </Typography>   
                    <Grid container={true}>
                        <Grid item={true} xs={12} md={3}/>
                        <Grid 
                            item={true} 
                            component={Paper} 
                            xs={12} 
                            md={6} 
                            className="thank-you-paper"
                        >
                            <Typography variant="h6" gutterBottom={true} align="center">
                                We are serious about protecting against counterfeit.
                            </Typography>
                            <Typography variant="h6" gutterBottom={true} align="center">
                                Invite a friend to join the fight!
                            </Typography>
                            <Button 
                                variant="contained"
                                href={mail}
                            >
                                Invite a friend
                            </Button>
                        </Grid>
                        <Grid item={true} xs={12} md={3}/>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};