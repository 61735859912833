import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import FindOutForm from '../common/FindOutForm';
import './ContactUs.css';

const submitEmail = () => {
    // do axios submit mail
};

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} xs={12} sm={6} className="contactus-grid-container">
                <Grid container={true} className="contactus-container contactus-fields-container">
                    <Grid item={true} xs={12}>
                        <Typography variant="h4" className="title-main">CONTACT US</Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Box className="box-divider" />
                    </Grid>
                    <Grid item={true} xs={12} lg={8}>
                        <Grid container={true}>
                            <Grid item={true} xs={12} className="contactus-phone contactus-field-container">
                                <Typography variant="body1">+65 6440 0874</Typography>
                            </Grid>
                            <Grid item={true} xs={12} className="contactus-field-container">
                                <Typography variant="body1">sales_web@opsolutions.biz</Typography>
                            </Grid>
                            <Grid item={true} xs={12} className="contactus-field-container">
                                <Typography variant="body1">
                                    116 Changi Road, #03-11,WIS@Changi, Singapore 419718
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                   
                </Grid>
            </Grid>
            <Grid item={true} xs={12} md={6} className="contactus-findout-container">
                <FindOutForm clearBackground={true} />
            </Grid>
        </Grid>
    );
};