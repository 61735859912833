import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './Analytics.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container" xs={12}>
                <Typography 
                    variant="h6" 
                    className="title-main" 
                    align="center"
                >
                    ANALYTICS PROGRAM
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider" />
            </Grid>
            <Grid item={true} xs={12}>
                <Grid container={true} className="analytics-main-container">
                    <Grid item={true} xs={12} sm={6}>
                        <Typography className="analytics-text">
                            Make use of our multi-purpose analytics program paired with our
                            authentication app to acquire complete data on your products and
                            market. Access on-the-go anytime through your phone or computer.
                            Upgrade with Dashboard for real-time tracking.
                        </Typography>
                        <ul>
                            <li>Identify potential counterfeit attempts</li>
                            <li>Manage your distribution/sales channel</li>
                            <li>Gather consumer insights as they scan</li>
                            <li>Analyse data for sales and marketing strategies</li>
                        </ul>
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <img
                            src={require('../../assets/images/analytics.png')}
                            alt=""
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};