import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default () => {
    return (
        <List>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Protect brand’s reputation and revenue lost</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Increase consumer engagement</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Boost marketing with consumer data</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Channel management and track counterfeit attempts</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>
                    Contribute to corporate social responsibility
                </ListItemText>
            </ListItem>
        </List>
    );
};