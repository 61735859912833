import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import FindOut from '../common/FindOut';
import HomeTypography from './HomeTypography';
import { HashLink as Link } from 'react-router-hash-link';
import './Home.css';

class Home extends React.Component {
  public render() {
    return (
      <>
        <Box className="home-bg" />
            <Grid container={true} className="container">
                <Grid item={true} xs={12} lg={6}>
                    <HomeTypography />
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                    <FindOut clearBackground={false} />
                </Grid>
                <Grid item={true} xs={12}>
                <Box className="home-scroll-down-container">
                    <Typography variant="caption">Scroll Down</Typography>
                    <Link smooth={true} to="/#experience-section">
                        <IconButton>
                            <ExpandMoreIcon />
                        </IconButton>
                    </Link>
                </Box>
            </Grid>
        </Grid> 
      </>
    );
  }
}

export default Home;
