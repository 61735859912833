import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default () => {
    return (
        <List>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Elevate trust in your brand</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Strengthen supply chain with unique offer</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Enhance confidence to drive new and existing business</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Transparency of business and commitment to quality assurance</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>
                    Assist in meeting specifications and ethical standards
                </ListItemText>
            </ListItem>
        </List>
    );
};