import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MarketingBrand from './MarketingBrand';

import './BoostMarketing.css';
export default () => {
    return (
        <Grid container={true} className="container">
            <Box className="boostmarketing-bg-container" />
            <Grid item={true} xs={12} className="boostmarketing-content-container">
                <Grid container={true} className="boostmarketing-content">
                    <Grid item={true} xs={12} md={5} className="marketing-img-container">
                        {/* <img 
                            src={require('../../assets/images/authenticationapp.png')} 
                        /> */}
                        <iframe 
                            className="boostmarketing-img"
                            src="https://www.youtube.com/embed/sy4QlgeyWGA" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen={true}
                            style={{
                                width: '100%',
                                height: '100%',
                                minHeight: 500
                            }}
                        /> 
                    </Grid>  
                    <Grid item={true} xs={12} md={7} className="marketing-labels-container">
                        <Grid item={true} xs={12} className="marketing-labels-container" lang="en">
                               <MarketingBrand
                                    image={require('../../assets/images/check.png')}
                                    title="Brand Protection"
                                    description="Deters counterfeit of both your product or our labels. Receive monthly reports or
                                    upgrade to our dashboard for real-time tracking on any potential counterfeit attempts
                                    using our Analytics Program."
                               />
                               <MarketingBrand
                                    image={require('../../assets/images/handphone.png')}
                                    title="Consumer Engagement"
                                    description="Encourages interacting with your product and promotes brand transparency. 
                                    Customize the information you want displayed upon scanning the labels, 
                                    direct consumers to your website, and/or prompt them to join in your 
                                    loyalty program."
                               />
                               <MarketingBrand
                                    image={require('../../assets/images/insight.png')}
                                    title="Marketing Insights"
                                    description="Collect valuable consumer insights using our Analytics program such as 
                                    location, user demographics, and popular product. 
                                    Consumers may be directed to your website through our 
                                    authentication app."
                               />
                        </Grid>
                    </Grid>    
                </Grid>
            </Grid>
        </Grid>
    );
};