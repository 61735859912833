import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';
import './Introduction.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container" xs={12}>
                <Typography 
                    variant="h6" 
                    className="title-main" 
                    align="center"
                >
                    PRODUCT AUTHENTICATION APP SOLUTION
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider" />
            </Grid>
            {/* <Grid item={true} xs={12} className="features-content-container">
                <ReactPlayer 
                    url={require('../../assets/videos/samplevideo.mp4')} 
                    playing={false} 
                    width="100%"
                    height="100%"
                    controls={true}
                />
            </Grid> */}
        </Grid>
    );
};