import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import UserExperienceStep from './UserExperienceStep';
import './UserExperience.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container" xs={12}>
                <Typography variant="h4" className="title-main" align="center">USER EXPERIENCE</Typography>
                <Typography variant="h5" className="title-subtitle" align="center">
                    Authenticating the product is as easy as a second!
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider" />
            </Grid>
            <Grid item={true} xs={12} className="full-width-container ux-step-by-app-group-container">
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={6} className="ux-app-group ux-app-group-image-container">
                        <img src={require('../../assets/images/app-group.png')} className="app-group-img" />
                    </Grid>
                    <Grid item={true} xs={12} sm={6} className="ux-app-group ux-app-group-typography-container">
                        <Grid container={true}>
                            <Grid item={true} xs={12} md={6}>
                                <Typography variant="h5" align="center">
                                    Any device with a camera and app store can download the 
                                    REAL barcode 
                                    application
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box  className="ux-step-by-step-top" />
            <Grid item={true} xs={12} className="full-width-container ux-step-by-step-container">
                <Grid item={true} xs={12}>
                    <UserExperienceStep 
                        title="Open the application and it will prompt users to scan" 
                        src={require('../../assets/images/step-1.png')} 
                        direction="row"
                        stepNumber="1"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <UserExperienceStep
                        title="Green tick means that the product is verified as real!" 
                        src={require('../../assets/images/step-2.png')} 
                        direction="row-reverse" 
                        stepNumber="2"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <UserExperienceStep
                        title="If it fails user can press the red button to report back" 
                        src={require('../../assets/images/step-3.png')} 
                        direction="row" 
                        stepNumber="3"
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <UserExperienceStep
                        title="Users can check the product details here including the shipping process" 
                        src={require('../../assets/images/step-4.png')} 
                        direction="row-reverse"
                        stepNumber="4" 
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
