import React from 'react';

class NoSSR extends React.Component {
    public state = {
      isClient: false
    };
    public componentDidMount() {
       this.setState({ isClient: true });
    }
    public render() {
      const { isClient } = this.state;
      const { children } = this.props;
      return isClient ? children : null;
    }
}

export default NoSSR;