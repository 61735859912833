import React from 'react';
import Grid from '@material-ui/core/Grid';
import BenefitCircle from './BenefitCircle';

export default () => {
    return (
        <Grid container={true} className="benefits-circles-container">
            <Grid item={true} xs={6} md={3} >
                <BenefitCircle 
                    src={require('../../assets/images/health-supplement.png')} 
                    height="100%"
                    title="Health Supplement"
                />
            </Grid>
            <Grid item={true} xs={6} md={3} >
                <BenefitCircle 
                    src={require('../../assets/images/cosmetics.png')} 
                    height="80%"
                    title="Cosmetics"
                />
            </Grid>
            <Grid item={true} xs={6} md={3} >
                <BenefitCircle 
                    src={require('../../assets/images/food.png')} 
                    height="65%"
                    title="Food/FMCG"
                />
            </Grid>
            <Grid item={true} xs={6} md={3} >
                <BenefitCircle 
                    src={require('../../assets/images/other-branded.png')} 
                    height="70%"
                    title="Other Branded Product"
                />
            </Grid>
        </Grid>
    );
};