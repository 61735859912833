import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default () => {
    return (
        <List>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Identify trustworthy brands and products</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Protect health and well-being</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Assist in choice selection and future purchase</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Better shopping experience and peace of mind</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>
                    Strengthens belief and favour towards brand and products
                </ListItemText>
            </ListItem>
        </List>
    );
};