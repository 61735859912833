import React from 'react';
import Box from '@material-ui/core/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import './AboutUs.css';
export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} xs={12} sm={6}>
                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <Typography variant="h4" className="title-main">ABOUT OPSOLUTIONS</Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Box className="box-divider" />
                    </Grid>
                    <Grid item={true} xs={12} className="aboutus-content-container">
                        <Typography variant="body1" className="aboutus-content">
                        OPSOLUTIONS is a regional technology firm that guides 
                        clients through a journey of digital transformation 
                        using a holistic approach. By focusing on foundational 
                        aspects, we provide devices, custom solutions, 
                        infrastructure, cloud, and cybersecurity to support 
                        and improve operational processes. We believe in 
                        building a strong community by strengthening brand 
                        and consumer interaction. Together, let's build an 
                        engaged future.
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} className="aboutus-button-container">
                        <ButtonBase className="aboutus-findout-more">
                            <Link 
                                href="https://opsolutions.biz/" 
                                className="highlight"
                            >
                                Find out more 
                            </Link>
                            <ArrowForwardIcon />
                        </ButtonBase>
                    </Grid>
                    <Grid item={true} xs={12} className="contactus-partnership-container">
                        <Box className="contactus-partnership-content" component="span">
                            In partnership with GS1 Hong Kong
                        </Box>
                        <Box className="highlight" component="span">
                            <Link 
                                href="https://www.gs1hk.org/REALvisibility/overview" 
                                className="highlight"
                            >
                                {' REAL Barcode.'}
                            </Link>
                        </Box>
                        <Typography className="contactus-partnership-content">
                            In partnership with GS1 Singapore as our Marketing Partner.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={6} />
        </Grid>
    );
};