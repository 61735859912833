import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Typography from '@material-ui/core/Typography';
import Manufacturers from './Manufacturers';
import Distributors from './Distributors';
import Customers from './Customers';
import BrandOwners from './BrandOwners';

export default () => {
    const [open, toggle] = React.useState(false);
    const onPress = () => {
        toggle(!open);
    };

    return (
        <Grid container={true} className={`benefits-list-main-container ${open ? 'benefits-open' : 'benefits-close'}`}>
            <Grid item={true} xs={12} md={6} className="benefits-list-container benefits-brandowners-list">
                <Typography variant="h5" className="benefits-list-title">BRAND OWNERS</Typography>
                <BrandOwners />
                <Box className="benefits-brandowners-bg " />
            </Grid>
            <Grid item={true} xs={12} md={6} className="benefits-list-container benefits-customers-list">
                <Typography variant="h5" className="benefits-list-title">CUSTOMERS</Typography>
                <Customers />
                <Box className="benefits-customers-bg " />
            </Grid>
            <Grid item={true} xs={12} md={6} className="benefits-list-container benefits-manufacturers-list">
                <Typography variant="h5" className="benefits-list-title">MANUFACTURERS</Typography>
                <Manufacturers />
                <Box className="benefits-manufacturers-bg" />
            </Grid>
            <Grid item={true} xs={12} md={6} className="benefits-list-container benefits-distributors-list">
                <Typography variant="h5" className="benefits-list-title">DISTRIBUTORS/RETAILERS</Typography>
                <Distributors />
                <Box className="benefits-distributors-bg" />
            </Grid>
            <Box className="benefits-clickable">
                <Button onClick={onPress} style={{ paddingBottom: 4 }}>
                    <Box>
                        <Box style={{ textTransform: 'none', color: '#CCCCCC' }}>
                            Click to {open ? 'hide' : 'see more'}
                        </Box>
                        {open ? 
                            <KeyboardArrowUpIcon htmlColor="#CCCCCC" fontSize="large" />
                        :
                            <KeyboardArrowDownIcon htmlColor="#CCCCCC" fontSize="large" />
                        }
                    </Box>
                </Button>
            </Box>
        </Grid>
    );
};
