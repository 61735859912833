import React from 'react';
import Box from '@material-ui/core/Box';

interface Props {
    image: string;
    title: string;
    description: string;
}

export default (props: Props) => {
    return (
        <Box className="marketing-brand">
            <Box className="marketing-brand-image-container">
                <img src={props.image} alt="" />
            </Box>
            <Box className="marketing-text-container">
                <Box className="marketing-brand-title-container">
                    {props.title}
                </Box>
                <Box className="marketing-brand-title-descrtiption">
                    {props.description}
                </Box>
            </Box>
        </Box>
    );
};