import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export default ({ title, src, direction, stepNumber }) => {
    return (
        <Grid container={true} style={{ flexDirection: direction }}>
            <Grid item={true} xs={12} sm={8} className="ux-step-label">
                <Typography variant="h4" align="left">{title}</Typography>
                <Box className="step-number-container">{stepNumber}</Box>
            </Grid>
            <Grid 
                item={true} 
                xs={12} 
                sm={4} 
                className={direction === 'row' ? 
                    'ux-step-img-container ux-flex-start' : 
                    'ux-step-img-container ux-flex-end'
                }
            >
                <img src={src} />
            </Grid>
        </Grid>
    );
};