import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ListIcon from '@material-ui/icons/List';
import StarIcon from '@material-ui/icons/Star';
import PeopleIcon from '@material-ui/icons/People';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { HashLink as Link } from 'react-router-hash-link';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default () => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const Icons = ({name}) => {
    switch (name) {
        case 'Home': 
            return <HomeIcon />;
        case 'Benefits': 
            return <ListIcon />;
        case 'Experience': 
            return <StarIcon />;
        case 'Stories': 
            return <PeopleIcon />;
        case 'Contact': 
            return <ContactSupportIcon />;
        default:
            return null;
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="drawer-link">
        {['Home', 'Experience', 'Benefits', 'Stories', 'Contact'].map((text, index) => {
          let id = '';
          switch (text) {
            case 'Home': 
              id = 'home-section';
              break;
            case 'Benefits': 
              id = 'benefits-section';
              break;
            case 'Experience': 
              id = 'experience-section';
              break;
            case 'Stories': 
              id = 'customerstories-section';
              break;
            case 'Contact': 
              id = 'contactus-section';
              break;
            default:
              id = id;
              break;
          }
          return (
            <Link key={index} to={`/#${id}`}>
              <ListItem button={true} key={text}>
                  <ListItemIcon><Icons name={text}/></ListItemIcon>
                  <ListItemText primary={text} />
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box className="header-drawer-container">
        <Button onClick={toggleDrawer('top', true)}>
            <MenuIcon />
        </Button>
        <SwipeableDrawer
            anchor={'top'}
            open={state['top']}
            onClose={toggleDrawer('top', false)}
            onOpen={toggleDrawer('top', true)}
        >
        {list('top')}
        </SwipeableDrawer>
    </Box>
  );
};