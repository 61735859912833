import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import './Protection.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container" xs={12}>
                <Typography 
                    variant="h6" 
                    className="title-main" 
                    align="center"
                >
                    3 LAYER PROTECTION
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider" />
            </Grid>
            <Grid item={true} xs={12}>
                <Grid container={true}>
                    <Grid item={true} xs={12} className="protection-content-main-desc">
                        <Typography align="center">
                            More than a Qr Code or Barcode, 
                            our labels can be designed with 3-in-1 
                            Anti-forgery Technology that is both
                            visually appealing for consumers and 
                            made extra secured at the same time. 
                        </Typography>
                        <Typography align="center">
                            (Optical Diffractions and Unique ID 
                            are optional additional protection 
                            that brand owners may choose to adopt.)
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} className="protection-content-desktop">
                        <Grid container={true} className="protection-content-container">
                            <Grid 
                                item={true}
                                xs={3}
                                className="protection-content-left"
                            >
                                <Box>
                                    <Typography className="protection-title">
                                        1. Optical Diffraction
                                    </Typography>
                                    <Typography className="protection-description"> 
                                        Label changes colour and
                                        transforms the lady into a rabbit
                                        when tilted.
                                    </Typography>
                                    <Box className="protection-sub-desc">
                                        <Typography>
                                            - An alternative to using the
                                            app for scanning.
                                        </Typography>
                                        <Typography>
                                            - This feature makes it
                                            expensive for 3rd parties to
                                            replicate.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <img 
                                    src={require('../../assets/images/protection.png')} 
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </Grid>
                            <Grid item={true} xs={3} className="protection-content-right">
                                <Box>
                                    <Typography className="protection-title">
                                        2. Unique ID
                                    </Typography>
                                    <Typography className="protection-description"> 
                                        A unique ID for each label or
                                        each batch of labels for tracking.
                                        Recieve monthly reports or
                                        upgrade for real-time rp
                                    </Typography>
                                </Box>
                                <Box className="protection-cont-last">
                                    <Typography className="protection-title">
                                        3. REAL QR Specialty
                                    </Typography>
                                    <Typography className="protection-description"> 
                                        Uses Greyscale Squares
                                        Microprinting technology that
                                        prevents 3rd party from forging
                                        our labels.
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} className="protection-content-mobile">
                        <Grid container={true} className="protection-content-container">
                            <Grid item={true} xs={12} className="protection-content-mobile-row mobile-content-image-container">
                                <img 
                                    src={require('../../assets/images/mobile-brandlogo.png')} 
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </Grid>
                            <Grid item={true} xs={12} className="protection-content-mobile-row">
                                <Typography className="protection-title">
                                    1. Optical Diffraction
                                </Typography>
                                <Typography className="protection-description"> 
                                    Label changes colour and
                                    transforms the lady into a rabbit
                                    when tilted.
                                </Typography>
                                <Box className="protection-sub-desc">
                                    <Typography>
                                        - An alternative to using the
                                        app for scanning.
                                    </Typography>
                                    <Typography>
                                        - This feature makes it
                                        expensive for 3rd parties to
                                        replicate.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item={true} xs={12} className="protection-content-mobile-row">
                                <Typography className="protection-title">
                                    2. Unique ID
                                </Typography>
                                <Typography className="protection-description"> 
                                    A unique ID for each label or
                                    each batch of labels for tracking.
                                    Recieve monthly reports or
                                    upgrade for real-time rp
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={12} className="protection-content-mobile-row">
                                <Typography className="protection-title">
                                    3. REAL QR Specialty
                                </Typography>
                                <Typography className="protection-description"> 
                                    Uses Greyscale Squares
                                    Microprinting technology that
                                    prevents 3rd party from forging
                                    our labels.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};