import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import './Footer.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} xs={12}>
                <Grid container={true} className="footer-content-container">
                    <Grid item={true} xs={12} className="footer-social-media-container">  
                        <Link href="https://www.facebook.com/opsolutions.biz">
                            <img src={require('../../assets/images/facebook.svg')} width={32} />
                        </Link>
                        <Link href="https://www.linkedin.com/company/opsolutionsbiz/"> 
                            <img src={require('../../assets/images/linkedin.svg')} width={32} />
                        </Link>
                    </Grid>
                    <Grid item={true} xs={12} sm={9}>
                        Copyright OPSOLUTIONS 2020
                    </Grid>
                    {/* <Grid item={true} xs={12} sm={3} className="footer-policy-container">
                        <Box>Privacy Policy</Box>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    );
};