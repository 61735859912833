import React from 'react';
import Home from './components/home/Home';
import Problems from './components/home/Problems';
import BoostMarketing from './components/boostmarketing/BoostMarketing';
import Protection from './components/protection/Protection';
import Analytics from './components/analytics/Analytics';
import Antiphishing from './components/antiphishing/Antiphishing'; 
import Introduction from './components/introduction/Introduction';
import Benefits from './components/benefits/Benefits';
import UserExperience from './components/userexperience/UserExperience';
import Features from './components/features/Features';
import CustomerStories from './components/customerstories/CustomerStories';
import ContactUs from './components/contactus/ContactUs';
import AboutUs from './components/aboutus/AboutUs';
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import BackToTop from './components/common/BackToTop';
import ThankYou from './components/thankyou/ThankYou';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import NoSSR from '@material-ui/core/NoSsr';
import {
    Switch,
    Route,
} from 'react-router-dom';
import './App.css';

const App = () => (
    <Box className="app-container" id="app-container">
        <Box style={{ display: 'none!important' }}>
            <img src={require('./assets/images/favicon.png')} alt="" style={{ display: 'none '}} />
        </Box>
        <Header />
        <NoSSR>
            <BackToTop />
        </NoSSR>
        <Switch>
            <Route path="/" exact={true}>
                <Grid container={true}>
                    <Grid item={true} xs={12} id="home-section" className="home-section-container">
                        <Home />
                    </Grid>
                    <Grid item={true} xs={12} id="problems-section" className="problems-section-container">
                        <Problems />
                    </Grid>
                    <Grid item={true} xs={12} id="experience-section" className="introduction-section-container">
                        <Introduction />
                    </Grid>
                    <Grid item={true} xs={12} id="boostmarketing-section" className="boostmarketing-section-container">
                        <NoSSR>
                            <BoostMarketing />
                        </NoSSR>
                    </Grid>
                    <Grid item={true} xs={12} id="protection-section" className="protection-section-container">
                        <NoSSR>
                            <Protection />
                        </NoSSR>
                    </Grid>
                    <Grid item={true} xs={12} id="protection-section" className="analytics-section-container">
                        <Analytics />
                    </Grid>
                    <Grid item={true} xs={12} id="antiphishing-section" className="antiphishing-section-container">
                        <Antiphishing />
                    </Grid>
                    <Grid item={true} xs={12} id="ux-section" className="ux-section-container">
                        <UserExperience />
                    </Grid>
                    <Grid item={true} xs={12} id="benefits-section" className="benefits-section-container">
                        <Benefits />
                    </Grid>
                    <Grid item={true} xs={12} id="usage-section" className="usage-section-container">
                        <Features />
                    </Grid>
                    <Grid 
                        item={true} 
                        xs={12} 
                        id="customerstories-section" 
                        className="customerstories-section-container"
                    >
                        <NoSSR>
                        <CustomerStories />
                        </NoSSR>
                    </Grid>
                    <Grid item={true} xs={12} id="contactus-section" className="contactus-section-container">
                        <ContactUs />
                    </Grid>
                    <Grid item={true} xs={12} id="aboutus-section" className="aboutus-section-container">
                        <AboutUs />
                    </Grid>
                    <Grid item={true} xs={12} className="footer-section-container">
                        <Footer />
                    </Grid>
                </Grid>
            </Route>
            <Route path="/thank_you" exact={true}>
               <Grid container={true}>
                    <Grid item={true} xs={12} className="thanks-section-container">
                        <ThankYou />
                    </Grid>
               </Grid>
            </Route>
        </Switch>
  </Box>
);

export default App;
