import React from 'react';
import Box from '@material-ui/core/Box';
import { HashLink as Link } from 'react-router-hash-link';
import Drawer from './Drawer';
import './Header.css';

class Header extends React.Component {
    public render() {
        return (
            <Box className="header-container" id="header-container">
                <Box className="header-logo-container">
                    <img className="header-logo" src={require('../../assets/images/ops-logo.png')} />
                </Box>
                <Box className="header-menu-container">
                    <Box className="header-menu">
                        <Link smooth={true} to="/#home-section">Home</Link>
                    </Box>
                    <Box className="header-menu">
                        <Link smooth={true} to="/#experience-section">Experience</Link>
                    </Box>
                    <Box className="header-menu">
                        <Link smooth={true} to="/#benefits-section">Benefits</Link>
                    </Box>
                    <Box className="header-menu">
                        <Link smooth={true} to="/#customerstories-section">Stories</Link>
                    </Box>
                    <Box className="header-menu">
                        <Link smooth={true} to="/#contactus-section">Contact Us</Link>
                    </Box>
                </Box>
                <Box className="header-drawer-container">
                    <Drawer />
                </Box>
            </Box>
        );
    }
}

export default Header;