import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BenefitsCircles from './BenefitsCircles';
import BenefitsList from './BenefitsList';
import './Benefits.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container" xs={12}>
                <Typography variant="h4" className="title-main" align="center">BENEFITS</Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider" />
            </Grid>
            <Grid item={true} xs={12} className="benefits-circles">
                <BenefitsCircles />
            </Grid>
            <Grid item={true} xs={12} className="benefits-list">
                <BenefitsList />
            </Grid>
        </Grid>
    );
};