import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import './Antiphishing.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container" xs={12}>
                <Typography 
                    variant="h6" 
                    className="title-main" 
                    align="center"
                >
                    ANTI-PHISHING   
                </Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider" />
            </Grid>
            <Grid item={true} xs={12}>
                <Grid container={true}>
                    <Grid item={true} xs={12} className="antiphishing-subtitle-container">
                        <Typography align="center">
                            Can you tell which is the original website? 
                        </Typography>
                        <Typography align="center">
                            Protect your brand and consumers from fake websites.
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Grid container={true} className="antiphishing-main-container">
                            <Grid item={true} xs={12} sm={12} lg={6}>
                                <img 
                                    src={require('../../assets/images/realsite.png')}
                                    alt=""
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={12} lg={6}>
                                <img 
                                    src={require('../../assets/images/fakesite.png')}
                                    alt=""
                                />
                            </Grid>
                            <Grid item={true} xs={12} sm={12} className="antiphising-text-container">
                                <Typography>
                                    It is common now for frauds to replicate 
                                    a landing pages which looks exactly the 
                                    same as the original business site. 
                                    This makes it difficult for consumers 
                                    to identify the difference and 
                                    unknowingly purchase counterfeit 
                                    products from these fake sites.
                                </Typography>
                                <Typography>
                                    With this Anti-phishing feature, our 
                                    system will track and inform you of 
                                    any attempts to copy your website.
                                </Typography>
                                <Typography>
                                    Get in touch with us to find out more.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};