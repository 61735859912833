import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CancelIcon from '@material-ui/icons/Cancel';

export default () => {
    return (
        <List>
            <ListItem>
                <ListItemIcon><CancelIcon htmlColor="#EF7070" /></ListItemIcon>
                <ListItemText>Damage to brand reputation</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CancelIcon htmlColor="#EF7070" /></ListItemIcon>
                <ListItemText>Impact business revenue</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CancelIcon htmlColor="#EF7070" /></ListItemIcon>
                <ListItemText>Compromise health and safety of consumers</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CancelIcon htmlColor="#EF7070" /></ListItemIcon>
                <ListItemText>Difficulty in managing quality assurance within channels</ListItemText>
            </ListItem>
        </List>
    );
};