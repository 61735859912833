import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FeatureList from './FeaturesList';
import './Features.css';

export default () => {
    return (
        <Grid container={true} className="container">
            <Grid item={true} className="title-container title-left" xs={12}>
                <Typography variant="h4" className="title-main white-title">FEATURES</Typography>
            </Grid>
            <Grid item={true} xs={12}>
                <Box className="box-divider white-divider" />
            </Grid>
            <Grid item={true} xs={12} className="features-list-container">
                <FeatureList />
            </Grid>
            <Grid item={true} xs={12} className="usage-content-container">
                <Grid container={true}>
                    <Grid item={true} xs={12} md={4} className="usage-content-item">
                    <Box className="features-img-hidden-bg" />
                        <Box className="features-img-hidden-text">
                            <Typography variant="h4" align="center" >
                                Seal
                            </Typography>
                        </Box>
                      <img src={require('../../assets/images/usage-1.png')} />
                    </Grid>
                    <Grid item={true} xs={12} md={4} className="usage-content-item">
                        <Box className="features-img-hidden-bg" />
                        <Box className="features-img-hidden-text">
                            <Typography variant="h4" align="center" >
                                Stick
                            </Typography>
                        </Box>
                        <img src={require('../../assets/images/usage-2.png')} />
                    </Grid>
                    <Grid item={true} xs={12} md={4} className="usage-content-item">
                        <Box className="features-img-hidden-bg" />
                        <Box className="features-img-hidden-text">
                            <Typography variant="h4" align="center" >
                                Sew
                            </Typography>
                        </Box>
                        <img src={require('../../assets/images/usage-3.png')} />
                    </Grid>
                </Grid>
            <Box className="usage-bg usage-bg-1" />
            <Box className="usage-bg usage-bg-2" />
            </Grid>
        </Grid>
    );
};