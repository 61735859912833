import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export default () => {
    return (
        <List>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Protect and promote business’s reputation</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Creates better customer journey</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Demonstrates commitment to safeguard consumer’s well-being</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>Deepen understanding of consumer behaviour</ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckCircleIcon htmlColor="#2196F3" /></ListItemIcon>
                <ListItemText>
                    Identify counterfeit attempts and act early
                </ListItemText>
            </ListItem>
        </List>
    );
};