import React, { useEffect, useState } from 'react';
import NoSSR from '../common/NoSSR';
import Story from './Story';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import './CustomerStories.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

// Hooks (High Order)
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(
        () => {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }
        
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, 
        []
    );
  
    return windowDimensions;
}

export default () => { 
    const { height, width } = typeof window === 'undefined' ? 
    { height: 0, width: 900 } 
    : useWindowDimensions();
    const slideHeight = width <= 900 ? 1 : width <= 1300 ? 1.1 : 0.85;  
    return (
        <NoSSR>
            <Grid container={true} className="container">
                <Grid item={true} className="title-container" xs={12}>
                    <Typography variant="h4" className="title-main">CUSTOMER STORY</Typography>
                </Grid>
                <Grid item={true} xs={12}>
                    <Box className="box-divider" />
                </Grid>
                <Grid item={true} xs={12} className="customer-story-content-container">
                    <Grid container={true}>
                        <Grid item={true} xs={12} md={4} className="customer-story-img">
                            <img 
                                src={require('../../assets/images/hcllogo.png')} 
                                className="cs-logo-container" 
                                style={{ maxHeight: '5rem'}} 
                            />
                            <img 
                                src={require('../../assets/images/hclproduct.png')} 
                                className="cs-product-container" 
                            />
                        </Grid>
                        <Grid item={true} xs={12} md={8} className="customer-story-text">
                            <Typography variant="caption" className="customer-story-drop-cap">
                                H
                            </Typography>
                            <Typography variant="h6">
                                    <span className="story-highlight">
                                        {`Hang Cheong Loong Bird's Nest & Ginseng Ltd `}
                                    </span>
                                    is a Chinese medicine wholesaler and retailer 
                                    who they place consumers health and safety 
                                    as their top priority. Adopting the REAL Qr, 
                                    their customers were empowered to authenticate 
                                    the medications, and report back on any product 
                                    that did not pass. This allowed H.C.L to work on 
                                    any anti-counterfeit plans or brand education. 
                                    Additionally, the scans assisted in collecting 
                                    analytics and customer insights to better work 
                                    with partners on future plans. The REAL Qr helped 
                                    in demonstrating their commitment to safeguard 
                                    consumer's health and boost trust in their brand.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                {/* {/* <Grid item={true} xs={12} className="customerstories-content-container">
                    <CarouselProvider
                        naturalSlideWidth={1}
                        naturalSlideHeight={slideHeight}
                        totalSlides={3}
                        isPlaying={true}
                        visibleSlides={width <= 900 ? 1 : 2}
                        interval={5000}
                        infinite={true}
                    >
                        <Slider>
                            <Slide index={0}>
                                <Story 
                                    img={require('../../assets/images/story-avatar-1.png')}
                                    text="Et netus et malesuada fames ac turpis egestas maecenas. 
                                    Vel risus commodo viverra maecenas accumsan lacus vel. 
                                    Elementum integer enim neque volutpat ac tincidunt vitae semper. 
                                    Vitae suscipit tellus… "
                                    name="John Doe"
                                />
                            </Slide>
                            <Slide index={1}>
                                <Story 
                                    name="Jerry Doe"
                                    img={require('../../assets/images/story-avatar-2.png')}
                                    text="Hang Cheong Loong Bird’s Nest &#38; Ginseng Ltd 
                                    is a Chinese medicine wholesaler and retailer who believes 
                                    in consumers health and safety as their top priority. 
                                    Adopting the REAL Qr Code, their customers were empowered 
                                    to authenticate the medications themselves. Products that 
                                    did not pass can be reported back allowing H.C.L to work on 
                                    anti-counterfeit plans or brand education. Collection of 
                                    customers analytics and insights assisted H.CL in improving 
                                    their sales channel and supply chain communication. With 
                                    the REAL Qr Code, H.C.L was able to demonstrate their 
                                    commitment to safeguard consumer’s health and boost trust 
                                    in their brand."
                                />
                            </Slide>
                            <Slide index={2}>
                                <Story 
                                    name="Miggy Doe"
                                    img={require('../../assets/images/story-avatar-3.jpg')}
                                    text="Vivamus congue, dolor a posuere consectetur, 
                                    ipsum dui faucibus eros, ut ornare felis eros in arcu. 
                                    In vehicula et orci eu congue. Vivamus mollis consequat 
                                    malesuada. Pellentesque ultricies elit ac magna malesuada 
                                    facilisis. Fusce facilisis augue vitae nisl mollis, a fermentum 
                                    leo pellentesque. Fusce vehicula, enim in mattis gravida, 
                                    erat massa euismod arcu, eu luctus diam diam id enim. 
                                    Praesent vel nisl viverra, interdum nunc a, varius sem. 
                                    Mauris pretium urna in turpis imperdiet vestibulum. 
                                    Praesent posuere, enim eget auctor tincidunt, neque 
                                    turpis iaculis purus, et ultricies ante ex sit amet 
                                    enim. Sed nisi lacus, gravida et lacinia non, congue 
                                    ut ligula. Praesent accumsan dolor dolor. Donec leo 
                                    tortor, laoreet ut finibus vitae, vulputate vitae eros."
                                />
                            </Slide>
                        </Slider>
                        <ButtonBack className="story-control customerstories-back-button">
                            <ChevronLeftIcon />
                        </ButtonBack>
                        <ButtonNext className="story-control customerstories-next-button">
                            <ChevronRightIcon />
                        </ButtonNext>
                    </CarouselProvider> 
                </Grid> */}
            </Grid>
        </NoSSR>
        
    );
};