import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';
import Typography from '@material-ui/core/Typography';

export default () => {
    return (
        <List>
            <ListItem>
                <ListItemIcon><CheckIcon htmlColor="#FFF" /></ListItemIcon>
                <ListItemText>
                    <Typography variant="h5">Flexible to embed into products</Typography>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckIcon htmlColor="#FFF" /></ListItemIcon>
                <ListItemText>
                    <Typography variant="h5">Capable of integrating with your printer</Typography>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckIcon htmlColor="#FFF" /></ListItemIcon>
                <ListItemText>
                    <Typography variant="h5">Patented technology to prevent fake copies</Typography>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemIcon><CheckIcon htmlColor="#FFF" /></ListItemIcon>
                <ListItemText>
                    <Typography variant="h5">Analytics to identify fraudulent reports</Typography>
                </ListItemText>
            </ListItem>
        </List>
    );
};