import React from 'react';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import NoSsr from '@material-ui/core/NoSsr';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseRounded';
import axios from 'axios';

interface Props {
    clearBackground: boolean;
}

const sendFindOutEmail = (data: any) => {
    return axios.post('/email/send', data);
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
        root: {
            display: 'flex',
            alignItems: 'center'
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
            width: '100%'
        },
        buttonFindOut: {
            'height': '8rem',
            'backgroundColor': '#448BCC',
            '&:hover': {
                backgroundColor: '#3073b0',
            },
        },
        buttonSuccess: {
            'backgroundColor': green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        buttonFail: {
            'backgroundColor': red[400],
            '&:hover': {
                backgroundColor: red[700],
            },
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        dialogTitle: {
            textAlign: 'center'
        },
        dialogContent: {
            padding: '0 2rem 2rem'
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialogInputRoot: {
            marginBottom: 0
        },
        dialogInput: {
            backgroundColor: '#E8F0FF',
            zIndex: 0,
            padding: '6px 0.5rem 7px'
        },
        dialogInputLabel: {
            zIndex: 1,
            pointerEvents: 'none',
            left: '0.5rem'
        },
        dialogTextAreaLabel: {
            zIndex: 1,
            pointerEvents: 'none',
            left: '0.5rem',
            top: '0.25rem'
        },
        shareTitle: {
            margin: '1rem 0 0'
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        gsButtons: {
            margin: '0.5rem 1rem',
        },
        gsButtonSelected: {
            backgroundColor: green[500],
            color: '#fff'
        },
        gsButtonNotSelected: {
            backgroundColor: '#fff'
        },
        sendButton: {
            'backgroundColor': '#448BCC',
            'color': 'white',
            '&:hover': {
                backgroundColor: '#3073B0',
            },
        },
        helperText: {
            color: '#F92126'
        }
    }),
);

export default (props: Props) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [isDialogVisible, toggleDialog] = React.useState(false);
    const [inputName, setInputName] = React.useState('');
    const [inputCompany, setInputCompany] = React.useState('');
    const [inputEmail, setInputEmail] = React.useState('');
    const [inputProductName, setInputProductName] = React.useState('');
    const [inputVolume, setInputVolume] = React.useState('');
    const [inputPriceRange, setInputPriceRange] = React.useState('');
    const [inputQuestions, setInputQuestions] = React.useState('');
    const [isGsMember, setGsMember] = React.useState(false);
    const [isNotGsMember, setNotGsMember] = React.useState(false);
    const [showHelpers, setShowHelpers] = React.useState(false);

    const buttonClassname = clsx({
        [classes.buttonFindOut]: true,
        [classes.buttonSuccess]: success,
        [classes.buttonFail]: failed,
    });

    const gsButtonClassname = clsx({
        [classes.gsButtons]: true,
        [classes.gsButtonSelected]: isGsMember,
        [classes.gsButtonNotSelected]: !isGsMember
    });

    const notGsButtonClassname = clsx({
        [classes.gsButtons]: true,
        [classes.gsButtonSelected]: isNotGsMember,
        [classes.gsButtonNotSelected]: !isNotGsMember
    });
    
    const handleButtonClick = () => {
        toggleDialog(true);
    };

    const handleSendClick = async () => {
        const data = {
            name: inputName,
            company: inputCompany,
            email: inputEmail,
            productName: inputProductName,
            volume: inputVolume,
            range: inputPriceRange,
            questions: inputQuestions,
            isGs1: isGsMember
        };

        if (!data.name || !data.company || !data.email || !(isGsMember || isNotGsMember)) {
            setShowHelpers(true);
        }
        else {
            setLoading(true);
            try {
                await sendFindOutEmail(data);
                window.location.href = '/thank_you';
            }
            catch (e) {
                console.log(e);
            }
            finally {
                setLoading(false);
            }
        }
    };

    const handleGsButtonClick = (type: 'MEMBER' | 'NOTMEMBER') => {
        switch (type) {
            case 'MEMBER': 
                setGsMember(true);
                setNotGsMember(false);
                break;
            case 'NOTMEMBER':
                setGsMember(false);
                setNotGsMember(true);
        }
    };

    const handleInput = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
        type: 'NAME'| 'COMPANY' | 'EMAIL' | 'PRODUCT' | 'VOLUME' | 'RANGE' | 'QUESTIONS'
    ) => {
        switch (type) {
            case 'NAME': 
                setInputName(e.target.value);
                break;
            case 'COMPANY': 
                setInputCompany(e.target.value);
                break;
            case 'EMAIL': 
                setInputEmail(e.target.value);
                break;
            case 'PRODUCT': 
                setInputProductName(e.target.value);
                break;
            case 'VOLUME': 
                setInputVolume(e.target.value);
                break;
            case 'RANGE': 
                setInputPriceRange(e.target.value);
                break;
            case 'QUESTIONS': 
                setInputQuestions(e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />'));
                break;
        }
    };

    const handleDialogClose = () => {
        toggleDialog(false);
        setGsMember(false);
        setNotGsMember(false);
        setShowHelpers(false);
    };

    const CustomDialogTitle = (props: any) => {
        const { children, onClose, classes, ...other } = props;
        return (
            <DialogTitle disableTypography={true} className={classes.root} {...other}>
            <Typography variant="h6" align="left">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
            </DialogTitle>
        );
    };

    return (
        <Box className="home-find-out-container">
            <Paper className={`home-find-out ${props.clearBackground ? 'find-out-clear' : ''}`} elevation={0}>
                <Box className="home-find-out-content">
                    <Typography variant="h4" className={props.clearBackground ? 'white-title' : ''}>
                        Find out more.
                    </Typography>
                    <NoSsr>
                        <Box className={classes.root}>
                            <Box className={classes.wrapper}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={buttonClassname}
                                    disabled={loading}
                                    onClick={handleButtonClick}
                                >
                                    <span style={{ textTransform: 'none' }}>Get free label samples</span>
                                </Button>
                            </Box>
                        </Box>
                    </NoSsr>
                </Box>
            </Paper> 
            <Dialog 
                open={isDialogVisible} 
                onClose={handleDialogClose} 
                maxWidth="xs"
                fullWidth={true}
            >
                <CustomDialogTitle 
                    className={classes.dialogTitle}
                    classes={classes}
                    onClose={handleDialogClose}
                >
                    Get free label samples
                </CustomDialogTitle>
                <DialogContent key="find-out-dialog-content" className={classes.dialogContent}>
                    <TextField
                        placeholder="Name"
                        label="Name *"
                        fullWidth={true}
                        className={classes.dialogInputRoot}
                        InputLabelProps={{ className: classes.dialogInputLabel }}
                        InputProps={{ disableUnderline: true, inputProps: { className: classes.dialogInput } }}
                        onChange={(e) => handleInput(e, 'NAME')}
                        FormHelperTextProps={{ className: classes.helperText }}
                        helperText={!inputName && showHelpers ? 'Please input your name' : ''}
                        autoComplete="new-password"
                    />
                    <TextField
                        placeholder="Company"
                        label="Company *"
                        fullWidth={true}
                        className={classes.dialogInputRoot}
                        InputLabelProps={{ className: classes.dialogInputLabel }}
                        InputProps={{ disableUnderline: true, inputProps: { className: classes.dialogInput }}}
                        onChange={(e) => handleInput(e, 'COMPANY')}
                        FormHelperTextProps={{ className: classes.helperText }}
                        helperText={!inputCompany && showHelpers ? 'Please input your company name' : ''}
                        autoComplete="new-password"
                    />
                    <TextField
                        placeholder="Email"
                        label="Email *"
                        fullWidth={true}
                        className={classes.dialogInputRoot}
                        InputLabelProps={{ className: classes.dialogInputLabel }}
                        InputProps={{ disableUnderline: true, inputProps: { className: classes.dialogInput } }}
                        onChange={(e) => handleInput(e, 'EMAIL')}
                        FormHelperTextProps={{ className: classes.helperText }}
                        helperText={!inputEmail && showHelpers ? 'Please input your email' : ''}
                        autoComplete="new-password"
                    />
                    <Typography variant="body1" className={classes.shareTitle}>
                        Could you share some information 
                        to us to understand your business 
                        better? (optional)
                    </Typography>
                    <TextField
                        placeholder="Name of the product for embedding codes"
                        label="Product Name"
                        fullWidth={true}
                        className={classes.dialogInputRoot}
                        InputLabelProps={{ className: classes.dialogInputLabel }}
                        InputProps={{ disableUnderline: true, inputProps: { className: classes.dialogInput } }}
                        onChange={(e) => handleInput(e, 'PRODUCT')}
                        autoComplete="new-password"
                    />
                    <TextField
                        placeholder="Estimated volume of the product"
                        label="Volume"
                        fullWidth={true}
                        className={classes.dialogInputRoot}
                        InputLabelProps={{ className: classes.dialogInputLabel }}
                        InputProps={{ disableUnderline: true, inputProps: { className: classes.dialogInput } }}
                        onChange={(e) => handleInput(e, 'VOLUME')}
                        autoComplete="new-password"
                    />
                    <TextField
                        placeholder="Price range of the product"
                        label="Price Range"
                        fullWidth={true}
                        className={classes.dialogInputRoot}
                        InputLabelProps={{ className: classes.dialogInputLabel }}
                        InputProps={{ disableUnderline: true, inputProps: { className: classes.dialogInput } }}
                        onChange={(e) => handleInput(e, 'RANGE')}
                        autoComplete="new-password"
                    />
                    <TextField
                        placeholder="Do you have specific questions for us?"
                        label="Additional Questions"
                        fullWidth={true}
                        className={classes.dialogInputRoot}
                        InputLabelProps={{ className: classes.dialogTextAreaLabel }}
                        InputProps={{ disableUnderline: true, inputProps: { className: classes.dialogInput }, rows: 5 }}
                        multiline={true}
                        onChange={(e) => handleInput(e, 'QUESTIONS')}
                        autoComplete="new-password"
                    />
                    <Typography variant="body1" className={classes.shareTitle}>
                        I am a GS1 Singapore Member *:
                    </Typography>
                    {!(isGsMember || isNotGsMember) && showHelpers ? 
                        <FormHelperText className={classes.helperText}>
                            Please select whether you are a GS1 member
                        </FormHelperText>
                    : null}
                    <Box className={classes.buttonContainer}>
                        <Button variant="contained" className={gsButtonClassname} onClick={() => handleGsButtonClick('MEMBER')}>
                            Yes
                        </Button>
                        <Button variant="contained" className={notGsButtonClassname} onClick={() => handleGsButtonClick('NOTMEMBER')}>
                            No
                        </Button>
                    </Box>
                    <Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        className={classes.sendButton}
                        onClick={() => handleSendClick()}
                    >
                       {loading ? <CircularProgress size={24} style={{ color: '#fff' }} /> : 'Send'}
                    </Button>
                </DialogContent>
            </Dialog>
        </Box>
    );
};