import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default ({ src, height, title }) => {
    return (
        <Box className="benefit-circle-container">
            <Box className="benefit-circle-image-container">
                <img 
                    src={src}
                    style={{ height }}
                />
            </Box>
            <Typography variant="h6" className="benefits-circle-title">{title}</Typography>
        </Box>
    );
};